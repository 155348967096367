"use client";

import { Fragment } from "react";
import { useLoggedIn } from "@/lib/auth";
import { Button } from "@/components/button";

export const SigninButton = () => {
  const loggedIn = useLoggedIn();

  return (
    <Fragment>
      {loggedIn ? (
        <Button asChild>
          <a href="/shelf/my">My Binders</a>
        </Button>
      ) : (
        <a href="/app/login">Login</a>
      )}
    </Fragment>
  );
};
